const Footer = () => {
  return (
    <footer className="py-3 mt-4 bg-dark mt-5">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3 ">
        <li className="nav-item">
          <a
            href="/home"
            className="nav-link px-2 text-light link-dark hover:bg-gray-400"
          >
            Home
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/avatars"
            className="nav-link px-2 text-light link-dark hover:bg-gray-400"
          >
            Avatars
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://emprise.cs.cornell.edu/sparcs/"
            className="nav-link px-2 text-light link-dark hover:bg-gray-400 "
          >
            SPARCS
          </a>
        </li>
      </ul>
      <div className="flex justify-center">
        <p className="text-center text-light link-dark hover:bg-gray-400 w-50 ">
          Questions or feedback? Contact us at empriselab21@gmail.com
        </p>
      </div>
    </footer>
  );
};

export default Footer;
