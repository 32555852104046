import { CommentProps, PaperProps, StateBoxProps } from "./interface/HSM";
import { ObjectId } from "bson";
import { BuildingBlocks } from "../model/BuildingBlocks";

export function getIndexOfState(
  parentProps: StateBoxProps,
  id: string
): number {
  if (!parentProps.children) {
    // children property is undefined. we treat this the same as
    // having no children
    return -1;
  }

  for (let i = 0; i < parentProps.children.length; i++) {
    if (parentProps.children[i].uid === id) {
      return i;
    }
  }

  return -1;
}

export function getIndexOfStateUid(
  parentProps: StateBoxProps,
  uid: string
): number {
  if (!parentProps.children) {
    // children property is undefined. we treat this the same as
    // having no children
    return -1;
  }

  for (let i = 0; i < parentProps.children.length; i++) {
    if (parentProps.children[i].uid === `${uid}`) {
      return i;
    }
  }

  return -1;
}

export function getPropsOfState(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  uid: string
): StateBoxProps | undefined {
  if (!parentProps.children || parentProps.children.length === 0) {
    return undefined;
  }

  if (relCrumbIds.length === 1) {
    return parentProps.children.find((child) => child.uid === uid);
  }

  const nextChildUid = relCrumbIds[1];
  const nextChild = parentProps.children.find(
    (child) => child.uid === nextChildUid
  );

  if (!nextChild) {
    return undefined;
  }

  return getPropsOfState(nextChild, relCrumbIds.slice(1), uid);
}

export function collectCommentsOneLevelDeep(
  state: StateBoxProps
): CommentProps[] {
  let comments: CommentProps[] = [];

  if (state.comments) {
    comments = comments.concat(
      state.comments.map((comment) => ({
        ...comment,
        stateText: state.text,
      }))
    );
  }

  if (state.children) {
    for (const child of state.children) {
      if (child.comments) {
        comments = comments.concat(
          child.comments.map((comment) => ({
            ...comment,
            stateText: child.text,
          }))
        );
      }
    }
  }

  return comments;
}

/**
 * Recursively retrieves an array of all states the user would see given the input crumb trail.
 * @param parentProps The current parent's props
 * @param relCrumbIds Relative crumb IDs. relCrumbIds[0] should always be parentProps. This defines
 * the path we take down the HSMs.
 * @returns An array containing the states the user would see on the current layer
 */
export function getStatesCurrentLayer(
  parentProps: StateBoxProps,
  relCrumbIds: string[]
): StateBoxProps[] {
  if (!parentProps.children) {
    // children property is undefined. we treat this the same as
    // having no children
    return [];
  }

  if (relCrumbIds.length === 1) {
    return parentProps.children;
  }

  let indexOfNextChild = getIndexOfState(parentProps, relCrumbIds[1]);

  if (indexOfNextChild === -1) {
    // child with the next ID has not been found
    return [];
  }

  return getStatesCurrentLayer(
    parentProps.children[indexOfNextChild],
    relCrumbIds.slice(1, undefined)
  );
}

export function getChildrenByUid(
  parentProps: StateBoxProps,
  targetUid: string
): StateBoxProps[] | undefined {
  // Base case: if the current state has the targetUid, return its children
  if (parentProps.uid === targetUid) {
    return parentProps.children || []; // Return empty array if no children
  }

  // If the current state has children, recursively search in them
  if (parentProps.children) {
    for (const child of parentProps.children) {
      const result = getChildrenByUid(child, targetUid);
      if (result) {
        return result; // If found, return the children of the found state
      }
    }
  }

  // Return undefined if no matching state with the targetUid is found
  return undefined;
}


export function getStateByBreadcrumb(
  parentProps: StateBoxProps,
  breadcrumbIds: string[]
): StateBoxProps | undefined {
  // Base case: if breadcrumbIds is empty, return the parentProps itself (this would be the final state in the breadcrumb path)
  if (breadcrumbIds.length === 0) {
    return parentProps;
  }

  // Recursive case: find the next child in the breadcrumb path
  const nextId = breadcrumbIds[0];
  
  // Check if this state has children
  if (parentProps.children) {
    // Find the child with the corresponding ID
    const childIndex = parentProps.children.findIndex((child) => child.uid === nextId);
    
    if (childIndex !== -1) {
      // Recursively find the next state in the breadcrumb path
      return getStateByBreadcrumb(parentProps.children[childIndex], breadcrumbIds.slice(1));
    }
  }

  // Return undefined if the breadcrumb ID is not found in the current state
  return undefined;
}


export function updateTitleOfActivity(
  activityProps: StateBoxProps,
  value: string
): StateBoxProps {
  return { ...activityProps, text: value };
}

export function updateBuildingBlocksOfActivity(
  activityProps: StateBoxProps,
  value: BuildingBlocks
): StateBoxProps {
  return { ...activityProps, buildingBlocks: value };
}

export function updatePropertyOfState(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  id: string,
  property: string,
  value: any
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    return parentProps.children.map((v) => {
      if (v.uid === id) {
        v[property] = value;
        v["uid"] = new ObjectId().toHexString();
      }
      return v;
    });
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? {
          ...v,
          children: updatePropertyOfState(
            v,
            relCrumbIds.slice(1),
            id,
            property,
            value
          ),
        }
      : v;
  });
}

/**
 * Recursively adds a state to the HSM, and returns the resulting children of the supplied parent after
 * the add operation.
 * @param parentProps The active parent state's props.
 * @param relCrumbIds Relative crumb IDs. relCrumbIds[0] should always be parentProps. This defines
 * the path we take down the HSMs.
 * @param predId The predecessor ID. The new state will be added directly after the state with this ID.
 * @param targetProps The props of the new state to add
 * @returns
 */
export function addState(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  predId: string,
  targetProps: StateBoxProps
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    // the predecessor state lives in the current layer.
    if (!predId) {
      // client is trying to add state to start of children array
      return [targetProps, ...parentProps.children];
    }

    // we return the children of the current parent, except with the target props added right after
    // the props of the predecessor state
    let predStateIndex = getIndexOfState(parentProps, predId);
    let beforeNewState = parentProps.children.slice(0, predStateIndex + 1);
    let afterNewState = parentProps.children.slice(
      predStateIndex + 1,
      parentProps.children.length
    );

    return [...beforeNewState, targetProps, ...afterNewState];
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? {
          ...v,
          children: addState(v, relCrumbIds.slice(1), predId, targetProps),
        }
      : v;
  });
}

export function addStateUid(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  predUid: string,
  targetProps: StateBoxProps
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    // the predecessor state lives in the current layer.
    if (!predUid) {
      // client is trying to add state to start of children array
      return [targetProps, ...parentProps.children];
    }

    // we return the children of the current parent, except with the target props added right after
    // the props of the predecessor state
    let predStateIndex = getIndexOfStateUid(parentProps, predUid);
    let beforeNewState = parentProps.children.slice(0, predStateIndex + 1);
    let afterNewState = parentProps.children.slice(
      predStateIndex + 1,
      parentProps.children.length
    );

    return [...beforeNewState, targetProps, ...afterNewState];
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? {
          ...v,
          children: addStateUid(v, relCrumbIds.slice(1), predUid, targetProps),
        }
      : v;
  });
}

export function deleteState(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  id: string
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    return parentProps.children.filter((v) => v.uid !== id);
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? { ...v, children: deleteState(v, relCrumbIds.slice(1), id) }
      : v;
  });
}

export function deleteStateUid(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  uid: string
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    return parentProps.children.filter((v) => v.uid !== uid);
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? { ...v, children: deleteStateUid(v, relCrumbIds.slice(1), uid) }
      : v;
  });
}

export function addComment(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  id: string,
  commentProps: CommentProps
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    // the state we are adding a comment to lives in the current layer.

    // we return the children of the current parent, except with the comment added to
    // the right state

    return parentProps.children.map((v) => {
      if (v.uid != id) {
        return v;
      }

      let currentComments = !v.comments ? [] : v.comments;

      return { ...v, comments: [...currentComments, commentProps] };
    });
  }

  let highLevelResult = parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? {
          ...v,
          children: addComment(v, relCrumbIds.slice(1), id, commentProps),
        }
      : v;
  });

  return highLevelResult;
}

export function addPaper(
  parentProps: StateBoxProps,
  relCrumbIds: string[],
  id: string,
  paperProps: PaperProps
): StateBoxProps[] {
  if (relCrumbIds.length === 1) {
    // the state we are adding a comment to lives in the current layer.

    // we return the children of the current parent, except with the comment added to
    // the right state

    return parentProps.children.map((v) => {
      if (v.uid != id) {
        return v;
      }

      let currentPapers = !v.papers ? [] : v.papers;

      return { ...v, papers: [...currentPapers, paperProps] };
    });
  }

  return parentProps.children.map((v) => {
    return v.uid === relCrumbIds[1]
      ? { ...v, children: addPaper(v, relCrumbIds.slice(1), id, paperProps) }
      : v;
  });
}

export function getNewStateProps(
  nextUID,
  layersBelow: number,
  setActivityProps,
  crumbIds,
  setCrumbIds,
  setCursor
) {
  const getNewText = () => {
    if (layersBelow === 4) {
      return "Activity";
    } else if (layersBelow === 3) {
      return "Composite task";
    } else if (layersBelow === 2) {
      return "Task";
    } else if (layersBelow === 1) {
      return "Composite skill";
    } else if (layersBelow === 0) {
      return "Skill";
    }
  };

  const getNewType = () => {
    if (layersBelow === 4) {
      return "activity";
    } else if (layersBelow === 3) {
      return "composite task";
    } else if (layersBelow === 2) {
      return "task";
    } else if (layersBelow === 1) {
      return "composite skill";
    } else if (layersBelow === 0) {
      return "skill";
    }
  };

  let newStateId = nextUID();
  let newStateUid = new ObjectId().toHexString();
  let onClickFunc =
    layersBelow === 0
      ? () => {}
      : () => {
          setCrumbIds((prevCrumbIds) => [...prevCrumbIds, newStateId]);
          setCursor("auto");
        };
  let newStateProps = {
    id: newStateId,
    type: getNewType(),
    text: getNewText(),
    uid: newStateUid,
    setText: (txt) => {
      setActivityProps((prevActivity) => {
        return {
          ...prevActivity,
          children: updatePropertyOfState(
            prevActivity,
            [...crumbIds],
            newStateId,
            "text",
            txt
          ),
        };
      });
    },
    setEditing: (editing) => {
      setActivityProps((prevActivity) => {
        return {
          ...prevActivity,
          children: updatePropertyOfState(
            prevActivity,
            [...crumbIds],
            newStateId,
            "editing",
            editing
          ),
        };
      });
    },
    deleteSelf: () => {
      setActivityProps((prevActivity) => {
        return {
          ...prevActivity,
          children: deleteState(prevActivity, [...crumbIds], newStateId),
        };
      });
    },
    onClick: onClickFunc,
    setCursorPointer: () => setCursor("pointer"),
    setCursorAuto: () => setCursor("auto"),
  };

  if (layersBelow === 0) {
    return { ...newStateProps, children: [] };
  }

  return {
    ...newStateProps,
    children: [
      getNewStateProps(
        nextUID,
        layersBelow - 1,
        setActivityProps,
        crumbIds,
        setCrumbIds,
        setCursor
      ),
    ],
  };
}

export function getStateTypeFromLayers(layersBelow: number) {
  switch (layersBelow) {
    case 3:
      return "composite task";
    case 2:
      return "task";
    case 1:
      return "composite skill";
    case 0:
      return "skill";
  }

  return "";
}

export function collectPapersOneLevelDeep(state: StateBoxProps): PaperProps[] {
  let papers: PaperProps[] = [];

  if (state.papers) {
    papers = papers.concat(
      state.papers.map((paper) => ({
        ...paper,
        stateText: state.text,
      }))
    );
  }

  if (state.children) {
    for (const child of state.children) {
      if (child.papers) {
        papers = papers.concat(
          child.papers.map((paper) => ({
            ...paper,
            stateText: child.text,
          }))
        );
      }
    }
  }

  return papers;
}
