import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CenteredSpinner } from "./Spinner";
import {
  Order,
  WorkflowProps,
  WorkflowTableProps,
} from "../utils/interface/ADLSPage";
import { getComparator, headCells, stableSort } from "../utils/ADLSPageUtils";
import ADLSPageTableHead from "./ADLSPageTableHead";
import ADLSPAGETableBody from "./ADLSPAGETableBody";
import ADLSPageSearch from "./ADLSPageSearch";
import ADLSRowcount from "./ADLSRowcount";
import ADLSPagination from "./ADLSPagination";

export function ADLSPageTable(props: WorkflowTableProps) {
  const { avatar } = useParams();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof WorkflowProps>("category");
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [adlsLoaded, setAdlsLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [refresh, setrefresh] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    filterRows(text);
  };

  const filterRows = (text) => {
    const lowercasedFilter = text.toLowerCase();
    const filteredData = rows.filter((row) => {
      return (
        row.name.toLowerCase().includes(lowercasedFilter) ||
        row.category.toLowerCase().includes(lowercasedFilter) ||
        row.status.toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredRows(filteredData);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WorkflowProps
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (url) => {
    window.open(url, "_self");
  };

  const calculatePageCount = (totalData, rowsPerPage) => {
    return Math.ceil(totalData / rowsPerPage) === 0
      ? 1
      : Math.ceil(totalData / rowsPerPage);
  };

  useEffect(() => {
    setpageCount(calculatePageCount(filteredRows.length, rowsPerPage));
  }, [rowsPerPage]);

  useEffect(() => {
    var url: string;
    const userContextData = sessionStorage.getItem("user");
    const userContext = JSON.parse(userContextData);

    const path = props.userContext
      ? // ? `/adls/${avatar}/author/${props.}`
        `/adls/${avatar}`
      : `/adls/${avatar}`;

    url = `${process.env.REACT_APP_BACKEND_BASE_URI}${path}`;

    fetch(url)
      .then((res) => res.json())
      .then((categories) => {
        const unapprovedWorkflows = [];
        const approvedWorkflows = [];

        categories.forEach((category) => {
          category.children.forEach((hsmObj) => {
            if (!userContext && !hsmObj.approved) {
              return;
            }
            if (!userContext && !hsmObj.visibility) {
              return;
            }
            const workflow = {
              id: hsmObj._id,
              name: hsmObj.text,
              category: hsmObj.adl,
              status: !hsmObj.visibility ? "Private" : "Public",
              url: (userContext.id!= hsmObj.author? "/view/" : "/edit/") + hsmObj._id,
              viewMode: hsmObj.viewMode,
              author: hsmObj.author,
              avatar: hsmObj.avatar,
              visibility: hsmObj.visibility,
              approved: hsmObj.approved,
              attributes: (hsmObj.attribute || "")
                .replace(/['"{\]}]+/g, "")
                .replace(/,/g, ", "),
            };

            if (!hsmObj.approved) {
              unapprovedWorkflows.push(workflow);
            } else {
              approvedWorkflows.push(workflow);
            }
          });
        });

        unapprovedWorkflows.sort((a, b) =>
          a.approved === b.approved ? 0 : a.approved ? 1 : -1
        );

        const sortedRows = [...unapprovedWorkflows, ...approvedWorkflows];

        setRows(sortedRows);
        setFilteredRows(sortedRows);
      })
      .then(() => {
        setrefresh(true);
        setAdlsLoaded(true);
      });
  }, [refresh]);

  return !adlsLoaded ? (
    <div className="row col-6  ">
      <CenteredSpinner />
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <div>
        <div className="flex gap-4 align-items-center mb-4">
          <ADLSRowcount
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <ADLSPageSearch onSearch={handleSearch} />
        </div>

        <div className="mb-2">
          <div className="w-full overflow-x-auto">
            <table className="min-w-full">
              <ADLSPageTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              {!refresh ? (
                <tbody>
                  <tr>
                    <td colSpan={headCells.length} className="text-center">
                      <CenteredSpinner />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <ADLSPAGETableBody
                  rows={filteredRows}
                  order={order}
                  orderBy={orderBy}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  handleClick={handleClick}
                  props={props}
                  setrefresh={setrefresh}
                />
              )}
            </table>
          </div>
        </div>
        <div className="flex align-items-center justify-center mt-5 ">
          <ADLSPagination
            count={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </Box>
  );
}

export default ADLSPageTable;
