import React from "react";

function AdminWorkflow() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h4 className="text-2xl font-semibold mb-4">Workflow Coming Soon</h4>
        <p className="text-gray-600">
          Stay tuned, we're working on bringing the workflows section soon.
        </p>
      </div>
    </div>
  );
}

export default AdminWorkflow;
