import React from "react";
import { LegendProps } from "../utils/interface/HSM";

const LegendItem = ({ label }) => (
  <div className="legend-item py-2 px-2 text-sm text-white hover:font-medium hover:bg-slate-300 rounded-md cursor-pointer transition-colors">
    {label}
  </div>
);

const Legend: React.FC<LegendProps> = ({ active, setActive }) => {
  return (
    <div className="legend-container space-y-4">
      {active ? (
        <div className="legend bg-gray-100 p-4 rounded-lg shadow-md space-y-2">
          {[
            "Activity",
            "Composite task",
            "Task",
            "Composite skill",
            "Skill",
          ].map((item) => (
            <LegendItem key={item} label={item} />
          ))}
          <button
            className="flex items-center justify-center p-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 transition-all"
            onClick={() => setActive(!active)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2 -2 24 24"
              width="20"
              fill="currentColor"
            >
              <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
              <path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path>
            </svg>
          </button>
        </div>
      ) : (
        <button
          className="flex items-center justify-center p-2 bg-green-500 text-white rounded-full shadow hover:bg-green-600 transition-all"
          onClick={() => setActive(!active)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            width="20"
            fill="currentColor"
          >
            <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-10a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm0-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Legend;
