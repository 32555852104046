import React from "react";
import { HSMContainerProps } from "../utils/interface/HSM";
import rightArrow from "../imgs/arrow.png";
import HSMStateBox from "./HSMStateBox";

const HSMContainer = ({
  stateAncestors,
  level,
  hsm,
  crumbIds,
  setCrumbIds,
  activityProps,
  setActivityProps,
  setLayer,
}: HSMContainerProps) => {
  const arrowImg = <img width={30} height={15} src={rightArrow} />;
  let containerClassName;
  let nextContainerClassName;
  let levelClassName;


  // console.log("data:", activityProps.children);

  switch (level) {
    case 0:
      containerClassName = "HSMContainer activity";
      nextContainerClassName = "HSMContainer comptask";
      levelClassName = "level0";
      break;
    case 1:
      containerClassName = "HSMContainer comptask";
      nextContainerClassName = "HSMContainer task";
      levelClassName = "level1";
      break;
    case 2:
      containerClassName = "HSMContainer task";
      nextContainerClassName = "HSMContainer compskill";
      levelClassName = "level2";
      break;
    case 3:
      containerClassName = "HSMContainer compskill";
      nextContainerClassName = "HSMContainer skill";
      levelClassName = "level3";
      break;
    case 4:
      containerClassName = "HSMContainer skill";
      levelClassName = "level4";
      break;
  }

  return (
    <div
      className={`${containerClassName} flex w-full max-w-full overflow-x-auto`}
      style={{ zIndex: 10 - level }}
    >
      <div className="flex space-y-2  flex-col  w-full max-w-full  relative">
        <HSMStateBox
          {...stateAncestors[0]}
          hideTools={true}
          imageLink={stateAncestors[0].imageLink || ""}
          videoLink={stateAncestors[0].videoLink || ""}
          setImageLink={(link) =>
            setActivityProps((prev) => ({ ...prev, imageLink: link }))
          }
          setVideoLink={(link) => {
            setActivityProps((prev) => ({ ...prev, videoLink: link }));
            console.log("video", link);
          }}
          onClickDeepout={() => {
            if (crumbIds.length > 1) {
              setCrumbIds(crumbIds.slice(0, -1));
            }
          }}
        />
        <div className={nextContainerClassName}>{hsm}</div>

      </div>
    </div>
  );
  // if (stateAncestors.length === 1) {
  //   return (
  //     <div
  //       className={`${containerClassName} flex w-full max-w-full overflow-x-auto`}
  //       style={{ zIndex: 10 - level }}
  //     >
  //       <div className="flex space-y-2  flex-col  w-full max-w-full  relative">
  //         <HSMStateBox
  //           {...stateAncestors[0]}
  //           hideTools={true}
  //           imageLink={stateAncestors[0].imageLink || ""}
  //           videoLink={stateAncestors[0].videoLink || ""}
  //           setImageLink={(link) =>
  //             setActivityProps((prev) => ({ ...prev, imageLink: link }))
  //           }
  //           setVideoLink={(link) => {
  //             setActivityProps((prev) => ({ ...prev, videoLink: link }));
  //             console.log("video", link);
  //           }}
  //           onClickDeepout={() => {
  //             if (crumbIds.length > 1) {
  //               setCrumbIds(crumbIds.slice(0, -1));
  //             }
  //           }}
  //         />
  //         <div className={nextContainerClassName}>{hsm}</div>

  //       </div>
  //     </div>
  //   );
  // }

  // if (stateAncestors.length > 1) {

  //   return (
  //     <div
  //       className={`${containerClassName} flex space-y-2 flex-col w-full max-w-full overflow-x-auto`}
  //       style={{ zIndex: 10 - level }}
  //     >
  //       <div className="flex space-y-4  flex-col w-full max-w-full  relative">
  //         <HSMStateBox
  //           {...stateAncestors[0]}
  //           hideTools={false}
  //           setImageLink={(link) =>
  //             setActivityProps((prev) => ({ ...prev, imageLink: link }))
  //           }
  //           setVideoLink={(link) =>
  //             setActivityProps((prev) => ({ ...prev, videoLink: link }))
  //           }
  //           onClickDeepout={() => {

  //             if (crumbIds.length > 1) {
  //               setCrumbIds(crumbIds.slice(0, -1));
  //             }
  //           }}
  //         />

  //         <HSMContainer
  //           stateAncestors={stateAncestors.slice(1)}
  //           level={level + 1}
  //           hsm={hsm}
  //           crumbIds={crumbIds}
  //           setCrumbIds={setCrumbIds}
  //           activityProps={activityProps}
  //           setActivityProps={setActivityProps}
  //           setLayer={setLayer}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  return null;
};

export default HSMContainer;
