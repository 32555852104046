import React, { useState } from "react";
import {
  StateTransition,
  TRANSITION_ORIENTATION,
  TRANSITION_TYPE,
} from "../utils/HSMUtils";
import {
  HSMViewProps,
  TransitionProps,
  StateBoxProps,
} from "../utils/interface/HSM";
import {
  deleteStateUid,
  getChildrenByUid,
  getStateByBreadcrumb,
  getStatesCurrentLayer,
  updatePropertyOfState,
} from "../utils/stateUtils";
import HSMStateBox from "./HSMStateBox";
import { ActivityProps } from "../utils/ADLs";

const HSMView = ({
  activityProps,
  setActivityProps,
  crumbIds,
  setCrumbIds,
  setLayer,
  discussionButtons,
  viewOnlyMode,
  editing,
  setCursor,
  nextUID,
  setShowingCommentsFor,
  setShowingPapersFor,
  setIsUnsavedChanges,
  isHumanMode,
}: HSMViewProps) => {
  // const statesProps = activityProps.pa getStateByBreadcrumb(activityProps, crumbIds);
  const [statesProps, setStatesProps] = useState<StateBoxProps[]>(activityProps.children);
  const stateElements = [];
  console.log("statesProps", statesProps);

  if (!Array.isArray(statesProps) || statesProps.length === 0) {
    return <h1 className="mx-auto">Workflow coming soon</h1>;
  }

  statesProps.forEach((state, i) => {
    const onClickFunc =
      !state.children ||
        state.children.length === 0 ||
        // crumbIds.length === 4 ||
        (isHumanMode && crumbIds.length === 2)
        ? () => {
          console.log("!state.children", state.children);
          console.log("state.children.length === 0", state.children.length === 0);
          console.log("crumbIds.length === 4", crumbIds.length === 4);
          console.log("isHumanMode && crumbIds.length === 2", isHumanMode && crumbIds.length === 2);

        }
        : () => {
          console.log("state", state);

          setCrumbIds((prevCrumbIds) => [...prevCrumbIds, state.uid])

          const childList = getChildrenByUid(activityProps, state.uid);

          // Find the index of the state.uid in the current statesProps
          const index = statesProps.findIndex((s) => s.uid === state.uid);

          if (index !== -1 && childList) {
            // Add the children right after the state at the found index
            const newStatesProps = [
              ...statesProps.slice(0, index + 1), // All items before and including the current state
              ...childList, // Add the children
              ...statesProps.slice(index + 1), // All items after the current state
            ];

            setStatesProps(newStatesProps);
          }
          setLayer(state.children[0].uid);
          setCursor("auto");

        };

    const setCursorPointerFunc =
      !state.children ||
        state.children.length === 0 ||
        crumbIds.length === 4 ||
        (isHumanMode && crumbIds.length === 2)
        ? () => { }
        : () => setCursor("pointer");

    const stateProps = {
      viewOnlyMode,
      text: state.text,
      imageLink: state.imageLink,
      videoLink: state.videoLink,
      setText: (txt) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "text",
            txt
          );
          return updatedActivity;
        });
      },
      setImageLink: (link) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "imageLink",
            link
          );
          return updatedActivity;
        });
      },
      setVideoLink: (link) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "videoLink",
            link
          );
          return updatedActivity;
        });
      },
      setPreconditions: (prec) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "preconditions",
            prec
          );
          return updatedActivity;
        });
      },
      setPostconditions: (postc) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "postconditions",
            postc
          );
          return updatedActivity;
        });
      },
      setOwnership: (owner) => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "ownership",
            owner
          );
          return updatedActivity;
        });
      },
      discussionButtons,
      editing,
      setEditing: (editing) => {
        setActivityProps((prevActivity) => {
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          updatePropertyOfState(
            updatedActivity,
            crumbIds,
            state.uid,
            "editing",
            editing
          );
          return updatedActivity;
        });
      },
      deleteSelf: () => {
        setActivityProps((prevActivity) => {
          setIsUnsavedChanges(true);
          const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          deleteStateUid(updatedActivity, crumbIds, state.uid);
          return updatedActivity;
        });
      },
      showComments: () => setShowingCommentsFor(state.uid),
      showPapers: () => setShowingPapersFor(state.uid),
      onClick: onClickFunc,
      setCursorPointer: setCursorPointerFunc,
      setCursorAuto: () => setCursor("auto"),
      humanMode: isHumanMode,
    };

    stateElements.push(
      <div className=" flex justify-end " key={state.uid}>
        <HSMStateBox
          key={state.uid}
          {...state}
          {...stateProps}
          onClickDeepout={() => {
            console.log("crumbIds", crumbIds);
          
            if (crumbIds.length > 1) {
              setCrumbIds(crumbIds.slice(0, -1)); // Go back one step in the breadcrumb
            }
          
            // Find the index of the state.uid in the current statesProps
            const index = statesProps.findIndex((s) => s.uid === state.uid);
          
            if (index !== -1) {
              // Get the children of the current state
              const childList = getChildrenByUid(activityProps, state.uid);
          
              // If there are no children, do nothing
              if (childList.length === 0) {
                return;
              }
          
              // Check if the children are in the statesProps
              const childrenInStatesProps = childList.filter(child => 
                statesProps.some(stateProp => stateProp.uid === child.uid)
              );
          
              // If there are children in statesProps, remove them and their descendants
              if (childrenInStatesProps.length > 0) {
                // Get all descendants (children, grandchildren, etc.)
                const allDescendants = getAllDescendants(state, activityProps);
          
                // Filter out the descendants from statesProps
                const newStatesProps = statesProps.filter(state => {
                  // Return true if the state is not in the list of descendants to remove
                  return !allDescendants.some(descendant => descendant.uid === state.uid);
                });
          
                setStatesProps(newStatesProps);
              }
            }
          }}
          

        />
      </div>
    );

    if (editing && i < statesProps.length) {
      const transitionProps: TransitionProps = {
        type: TRANSITION_TYPE.BETWEEN,
        orientation: TRANSITION_ORIENTATION.RIGHT,
        adding: editing,
        predId: state.uid,
        nextUID: nextUID,
        crumbIds: crumbIds,
        setCrumbIds: setCrumbIds,
        setActivityProps: setActivityProps,
        setCursor: setCursor,
        setIsUnsavedChanges: setIsUnsavedChanges,
      };

      stateElements.push(
        <StateTransition key={`transition-${state.uid}`} {...transitionProps} />
      );
    }
  });

  return (
    <div className="w-full max-w-full overflow-x-auto mx-auto">
      <div className="flex flex-col space-y-2  ">{stateElements}</div>
    </div>
  );
};

export default HSMView;

function getAllDescendants(state: StateBoxProps, activityProps: StateBoxProps): StateBoxProps[] {
  const descendants: StateBoxProps[] = [];

  // Get the immediate children of the state
  const childList = getChildrenByUid(activityProps, state.uid);

  if (childList.length === 0) {
    return descendants;
  }

  // Add the children to the descendants list
  descendants.push(...childList);

  // Recursively add each child's children (grandchildren, etc.)
  childList.forEach(child => {
    const grandChildren = getAllDescendants(child, activityProps);
    descendants.push(...grandChildren);
  });

  return descendants;
}
