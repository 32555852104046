import { Button, responsiveFontSizes } from "@mui/material";
import Card from "../components/HomeAvatarCards";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/esm/Dropdown";
import SpacsBox from "../components/HeroSection";
import { redirect } from "react-router-dom";

const Homepage = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [avatars, setAvatars] = useState([]);
  const [avatarsLoaded, setAvatarsLoaded] = useState(false);

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatars`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const avatarsRes = await response.json();
      const avatarsData = avatarsRes.map((avatarObj) => ({
        title: avatarObj.name,
        _id: avatarObj._id,
        paragraph: avatarObj.description,
        imageUrl: avatarObj.image,
      }));

      setAvatars(avatarsData);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }
  }, [avatarsLoaded]);

  const buttons = [
    { id: 1, label: "Feature", content: "Content for Button 1", videoUrl: "https://www.youtube.com/watch?v=sgaKUSDOBEg" },
    { id: 2, label: "Feature", content: "Content for Button 2", videoUrl: "https://www.youtube.com/watch?v=aZ8NsGrJPi8" },
    { id: 3, label: "Feature", content: "Content for Button 3", videoUrl: "https://www.youtube.com/watch?v=YkBCLAD-M74" },
    { id: 4, label: "Feature", content: "Content for Button 4", videoUrl: "https://www.youtube.com/watch?v=wojGrtFMrkU" },
  ];

  const faqs = [
    { id: 1, question: "FAQ 1", answer: "Answer to FAQ 1" },
    { id: 2, question: "FAQ 2", answer: "Answer to FAQ 2" },
    { id: 3, question: "FAQ 3", answer: "Answer to FAQ 3" },
    { id: 4, question: "FAQ 4", answer: "Answer to FAQ 4" },
    { id: 5, question: "FAQ 5", answer: "Answer to FAQ 5" },
    { id: 6, question: "FAQ 6", answer: "Answer to FAQ 6" },
  ];

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const handleEmailRedirect = () => {
    window.location.href = 'mailto:empriselab21@gmail.com';
  };

  return (
    <div className="homepage">
      <div className="container-md mb-5">
        <div className="row">
          <SpacsBox
            heading="A platform that facilitates asynchronous discussion between stakeholders from the robotics and caregiving community"
            buttonTitle="Spacs Box"
          />
        </div>
      </div>

      <div className="container-md" role="main">
        <div className="row">
          <div className="home-content col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <p className="md-5 homepage-text">
              SPARCS-Box currently maintains a database of six real care
              recipients called Avatars. We host information on their physical
              measurements, simulated videos showing their mobility, and expert
              occupational therapists reviewed workflows for each. We give you
              the flexibility to view and edit existing workflows, create new
              ones, and discuss them with experts from the caregiving community
              on our platform.
            </p>

            <div className="container">
              <div className="row">
                {avatars.slice(0, 3).map((avatar, index) => (
                  <div className="col-lg" key={index}>
                    <div className="w-100">
                      <Card
                        imageUrl={
                          avatar.imageUrl ||
                          `./${avatar._id}.gif` ||
                          "./61ff3d84f7e387abdedc64e5.gif"
                        }
                        title={avatar.title || "welcome"}
                        paragraph={
                          avatar.paragraph ||
                          "PARCS-Box currently maintains a database of six real care recipients called Avatars. We host information on their physical measurements"
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="row justify-content-end">
              <Button
                variant="outlined"
                href="/avatars"
                style={{
                  width: "auto",
                  marginTop: "2em",
                  marginBottom: "5em",
                  color: "black",
                  borderRadius: "6px",
                  marginRight: "1.5vw",
                }}
                endIcon={<ArrowForwardIcon />}
              >
                View All Avatars
              </Button>
            </div>

            <div className="container text-center mt-5">
              <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>
                OUR FEATURES
              </h1>

              <div
                className="d-inline-block"
                style={{
                  backgroundColor: "black",
                  padding: "5px",
                  borderRadius: "50px",
                }}
              >
                {buttons.map((button, index) => (
                  <button
                    key={button.id}
                    className="btn custom-button"
                    style={{
                      backgroundColor:
                        activeButton === button.id ? "white" : "transparent",
                      color: activeButton === button.id ? "black" : "white",
                      padding: "10px 20px",
                      margin: "5px",
                      borderRadius:
                        activeButton === button.id ? "30px" : "30px",
                      border: "none",
                    }}
                    onClick={() => handleButtonClick(button.id)}
                  >
                    {button.label} {index + 1}
                  </button>
                ))}
              </div>
            </div>

            {/* Display Selected Video for Active Feature */}
            <div className="container mt-5">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                <iframe
        width="100%"
        height="400px"
        src={`https://www.youtube.com/embed/${buttons.find((button) => button.id === activeButton)?.videoUrl.split('=')[1]}`}
        title={`Feature ${activeButton} Video`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "10px" }}
      ></iframe>
                </div>
              </div>
            </div>

            {/* FAQs Section */}
            <div style={{ textAlign: "center", marginTop: "8vh" }}>
              <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>FAQs</h1>
            </div>
            <div style={{ textAlign: "center", marginTop: "1vh" }}>
              <h3 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                Have a question? We're here to help.
              </h3>
            </div>

            <div className="container mb-5">
              <div className="row justify-content-center">
                {faqs.map((faq) => (
                  <div key={faq.id} className="dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id={`faqDropdown${faq.id}`}
                        style={{
                          display: "flex",
                          boxShadow: "none",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "transparent",
                          width: "100%",
                          borderBottom: "2px solid grey",
                          borderTop: "none",
                          borderRight: "none",
                          paddingLeft: "0px",
                          borderLeft: "none",
                          borderRadius: "0px",
                          color: "black",
                        }}
                      >
                        {faq.question}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Dropdown.ItemText style={{ color: "black" }}>
                          {faq.answer}
                        </Dropdown.ItemText>
                      </Dropdown.Menu>
                    </Dropdown>
                    <br />
                  </div>
                ))}
              </div>
            </div>

            {/* Contact Section */}
            <div className="container mt-5" style={{ marginBottom: "20vh" }}>
              <div className="row justify-content-center mt-5">
                <div
                  className="p-4 bg-dark text-light"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="row">
                    <div className="col-md-8">
                      <h2>Still have questions?</h2>
                      <p style={{ fontSize: "0.9rem" }}>
                        Can't find the answer you're looking for? Please get in
                        touch with our team.
                      </p>
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-end">
                      <button onClick={handleEmailRedirect} className="btn btn-secondary mr-3">
                        Get in touch&nbsp;&nbsp;&rarr;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
