import React, { useState } from "react";
import { CommentsSidebar, PapersSidebar } from "../components/Sidebar";
import {
  CommentsSidebarProps,
  PapersSidebarProps,
} from "../components/Sidebar";
import rightArrow from "../imgs/arrow.png";
import {
  getPropsOfState,
  addComment,
  addPaper,
  collectCommentsOneLevelDeep,
  collectPapersOneLevelDeep,
  updateTitleOfActivity,
} from "../utils/stateUtils";
import {
  BCNavProps,
  CommentProps,
  HSMRendererProps,
  PaperProps,
  StateBoxProps,
  TitleInputProps,
} from "../utils/interface/HSM";
import BCNav from "../components/BreadCrumbNav";
import Legend from "../components/HSMLegend";
import { BuildingBlockTableContainer } from "../components/BuildingBlockTableContainer";
import HSMTitleInput from "../components/HSMTitleInput";
import HSMContainer from "../components/HSMContainer";
import SpacsBox from "../components/HeroSection";

const HSMRenderer = ({
  userContext,
  activityId,
  activityProps,
  setActivityProps,
  showingCommentsFor,
  setShowingCommentsFor,
  showingPapersFor,
  setShowingPapersFor,
  crumbIds,
  setCrumbIds,
  setLayer,
  setCursor,
  toolbar,
  children,
  editing,
  inEditMode = false,
  setIsUnsavedChanges,
  isHumanMode,
  center,
}: HSMRendererProps) => {
  const setCursorPointer = () => setCursor("pointer");
  const setCursorAuto = () => setCursor("auto");
  const [legendActive, setLegendActive] = useState(false);

  const updateEndpoint = editing
    ? `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/update/`
    : isHumanMode
      ? `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_view_human/update/`
      : `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_view/update/`;

  let commentsSidebar = null;

  if (showingCommentsFor) {
    let propsOfActiveCommentState = getPropsOfState(
      activityProps,
      crumbIds,
      showingCommentsFor
    );

    if (!propsOfActiveCommentState) {
      setShowingCommentsFor(undefined);
    } else {
      let commentsSidebarProps: CommentsSidebarProps = {
        stateProps: propsOfActiveCommentState,
        addComment: (commentText: string) => {
          let commentProps: CommentProps = {
            author: userContext.name,
            occupation: userContext.occupation,
            text: commentText,
            stateText: propsOfActiveCommentState.text,
          };
          let newActivityProps = {
            ...activityProps,
            children: addComment(
              activityProps,
              crumbIds,
              showingCommentsFor,
              commentProps
            ),
          };

          fetch(updateEndpoint + activityId, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },

            body: JSON.stringify(newActivityProps),
          }).then((res) => {
            if (res.ok) {
              setActivityProps(newActivityProps);
            }
          });
        },
        hideComments: () => setShowingCommentsFor(undefined),
        children: collectCommentsOneLevelDeep(propsOfActiveCommentState),
      };

      commentsSidebar = <CommentsSidebar {...commentsSidebarProps} />;
    }
  }

  let papersSidebar = null;

  if (showingPapersFor) {
    let propsOfActivePaperState = getPropsOfState(
      activityProps,
      crumbIds,
      showingPapersFor
    );

    if (!propsOfActivePaperState) {
      setShowingPapersFor(undefined);
    } else {
      let papersProps: PapersSidebarProps = {
        stateProps: propsOfActivePaperState,
        addPaper: (
          paperTitle: string,
          paperUrl: string,
          modelInfo: string = ""
        ) => {
          let paperProps: PaperProps = {
            title: paperTitle,
            author: userContext.name,
            url: paperUrl,
            modelInfo: modelInfo,
            stateText: propsOfActivePaperState.text,
          };
          let newActivityProps = {
            ...activityProps,
            children: addPaper(
              activityProps,
              crumbIds,
              showingPapersFor,
              paperProps
            ),
          };

          fetch(updateEndpoint + activityId, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newActivityProps),
          }).then((res) => {
            if (res.ok) {
              setActivityProps(newActivityProps);
            }
          });
        },
        hidePapers: () => setShowingPapersFor(undefined),
        children: collectPapersOneLevelDeep(propsOfActivePaperState),
      };

      papersSidebar = <PapersSidebar {...papersProps} />;
    }
  }

  const bcNavProps: BCNavProps = {
    crumbIds,
    setCrumbIds,
    setLayer,
    activityProps,
    onMouseEnterCrumb: setCursorPointer,
    onMouseLeaveCrumb: setCursorAuto,
  };

  if (crumbIds.length === 0) return null;

  function handleTitleChange(newTitle: string) {
    setActivityProps(updateTitleOfActivity(activityProps, newTitle));
  }

  const titleInputProps: TitleInputProps = {
    title: activityProps.text,
    setTitle: handleTitleChange,
    setIsUnsavedChanges,
  };

  let i = 1;
  let ancestorProps: StateBoxProps[] = [
    {
      type: "activity",
      text: activityProps.text,
      imageLink: "",
      videoLink: "",
      setImageLink: (link: string) =>
        setActivityProps((prev: any) => ({
          ...prev,
          imageLink: link,
        })),
      setVideoLink: (link: string) =>
        setActivityProps((prev: any) => ({
          ...prev,
          videoLink: link,
        })),
      setActivityProps: setActivityProps,
    },
  ];

  while (i < crumbIds.length) {
    ancestorProps.push(
      getPropsOfState(activityProps, crumbIds.slice(0, i), crumbIds[i])
    );
    i++;
  }

  return (
    <div className="col">
      <div className="container-md">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Workflow" />
        </div>
      </div>
      <div className="HSMRenderer" style={{ marginTop: "-70px" }}>
        {center()}
        <div className="flex items-center justify-between col-10 offset-1">
          <BCNav {...bcNavProps} />
          {toolbar}
        </div>

        {inEditMode && <HSMTitleInput {...titleInputProps} />}
        <div className="BuildingBlockContainer">
          <BuildingBlockTableContainer
            activityProps={activityProps}
            setActivityProps={setActivityProps}
            setIsUnsavedChanges={setIsUnsavedChanges}
            isEditable={inEditMode}
            isExpand={true}
            isHumanMode={isHumanMode}
          />
        </div>
        {children.map((child, idx) => (
          <div className="HSMContainerRoot" key={idx}>
            {idx === 0 && (
              <HSMContainer
                stateAncestors={ancestorProps}
                level={0}
                hsm={child}
                crumbIds={crumbIds}
                setCrumbIds={setCrumbIds}
                activityProps={activityProps}
                setActivityProps={setActivityProps}
                setLayer={setLayer}
              />
            )}
            {/* {idx !== 0 && child} */}
          </div>
        ))}
        <Legend active={legendActive} setActive={setLegendActive} />
        {commentsSidebar}
        {papersSidebar}
      </div>
    </div>
  );
};

export default HSMRenderer;
